// App.js
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import './App.css';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

// Helper functions
const formatAmount = (amount) => {
  if (amount === null || amount === undefined || isNaN(amount)) return 'N/A';
  if (amount >= 1e9)
    return (amount / 1e9).toFixed(2).replace(/\.00$/, '') + 'B';
  if (amount >= 1e6)
    return (amount / 1e6).toFixed(2).replace(/\.00$/, '') + 'M';
  if (amount >= 1e3)
    return (amount / 1e3).toFixed(2).replace(/\.00$/, '') + 'K';
  if (amount >= 1)
    return amount.toLocaleString(undefined, { maximumFractionDigits: 2 });
  if (amount >= 0.0001) return amount.toFixed(4);
  if (amount > 0) return amount.toFixed(8);
  return '0.0000';
};

const formatLargeNumber = (num) => {
  if (num >= 1e9) return (num / 1e9).toFixed(2).replace(/\.00$/, '') + 'B';
  if (num >= 1e6) return (num / 1e6).toFixed(2).replace(/\.00$/, '') + 'M';
  if (num >= 1e3) return (num / 1e3).toFixed(2).replace(/\.00$/, '') + 'K';
  return num.toFixed(2);
};

const formatPrice = (price) => {
  if (price === null || price === undefined || isNaN(price)) return 'N/A';
  if (price >= 1e9)
    return (price / 1e9).toFixed(2).replace(/\.00$/, '') + 'B';
  if (price >= 1e6)
    return (price / 1e6).toFixed(2).replace(/\.00$/, '') + 'M';
  if (price >= 1e3)
    return (price / 1e3).toFixed(2).replace(/\.00$/, '') + 'K';
  if (price >= 1)
    return price.toLocaleString(undefined, { maximumFractionDigits: 2 });
  if (price >= 0.0001) return price.toFixed(4);
  if (price > 0) return price.toFixed(8);
  return '0.00000000';
};

const formatPercentage = (value) => {
  if (value === null || value === undefined || isNaN(value)) return 'N/A';
  return value.toFixed(2) + '%';
};

const shortenAddress = (address, isTokenAddress = false) => {
  if (isTokenAddress) return `${address.slice(0, 2)}..${address.slice(-2)}`;
  else if (window.innerWidth <= 768)
    return `${address.slice(0, 2)}..${address.slice(-2)}`;
  return `${address.slice(0, 3)}...${address.slice(-3)}`;
};

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).catch((err) => {
    console.error('Failed to copy: ', err);
  });
};

const timeAgo = (timestamp, now = Date.now()) => {
  const diff = Math.floor((now - timestamp) / 1000);
  if (diff < 60) return `${diff}s ago`;
  if (diff < 3600) return `${Math.floor(diff / 60)}m ago`;
  if (diff < 86400) return `${Math.floor(diff / 3600)}h ago`;
  return `${Math.floor(diff / 86400)}d ago`;
};

const TimeAgo = React.memo(function TimeAgo({ timestamp }) {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000); // Update every second
    return () => clearInterval(intervalId);
  }, []);

  const timeAgoValue = useMemo(
    () => timeAgo(timestamp, currentTime),
    [timestamp, currentTime]
  );

  return <>{timeAgoValue}</>;
});

function Modal({ children, onClose }) {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
}

function App() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortParameter, setSortParameter] = useState('latestActivity');
  const [sortDisplayText, setSortDisplayText] = useState('');
  const sectionsPerPage = 10;
  const [showNotification, setShowNotification] = useState(true);
  const closeNotification = () => setShowNotification(false);
  const [pinnedPoolId, setPinnedPoolId] = useState(null);

  const [flashPoolCards, setFlashPoolCards] = useState({});
  const prevLatestTransactionDigests = useRef({});
  const initialLoadRef = useRef(true);

  const lastFetchedTimestampRef = useRef(null);

  const [modalPool, setModalPool] = useState(null);
  const [modalTransactions, setModalTransactions] = useState([]);
  const [modalPage, setModalPage] = useState(1);
  const [modalTotalPages, setModalTotalPages] = useState(1);
  const transactionsPerPage = 5;

  const [suiPrice, setSuiPrice] = useState(0);

  const fetchSuiPrice = useCallback(async () => {
    try {
      const response = await fetch(
        'https://api.coingecko.com/api/v3/simple/price?ids=sui&vs_currencies=usd'
      );
      const data = await response.json();
      if (data && data.sui && data.sui.usd) {
        setSuiPrice(data.sui.usd);
      }
    } catch (error) {
      console.error('Failed to fetch SUI price:', error);
    }
  }, []);

  useEffect(() => {
    fetchSuiPrice();
    const intervalId = setInterval(fetchSuiPrice, 60000);
    return () => clearInterval(intervalId);
  }, [fetchSuiPrice]);

  const fetchTransactions = useCallback(async () => {
    try {
      let url = `https://api.suifox.com/transactions?data=true`;

      // Always include lastTimestamp, if not available, use 0
      const lastTimestamp = lastFetchedTimestampRef.current || 0;
      url += `&lastTimestamp=${lastTimestamp}`;

      const response = await fetch(url);

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();

      if (loading) {
        setLoading(false);
      }

      if (data.length > 0) {
        setTransactions((prevTransactions) => {
          const combinedTransactions = [...prevTransactions];
          data.forEach((newPool) => {
            const existingPoolIndex = combinedTransactions.findIndex(
              (pool) => pool.poolId === newPool.poolId
            );
            if (existingPoolIndex > -1) {
              const existingPool = combinedTransactions[existingPoolIndex];

              const combinedTransactionsList = [
                ...(existingPool.transactions || []),
                ...newPool.transactions,
              ];
              const uniqueTransactions = Array.from(
                new Map(combinedTransactionsList.map((tx) => [tx.digest, tx]))
                  .values()
              )
                .sort((a, b) => b.timestampMs - a.timestampMs)
                .slice(0, 5);

              combinedTransactions[existingPoolIndex] = {
                ...existingPool,
                ...newPool,
                transactions: uniqueTransactions,
              };
            } else {
              newPool.transactions = (newPool.transactions || []).slice(0, 5);
              combinedTransactions.push(newPool);
            }
          });
          return combinedTransactions;
        });

        const latestTimestamp = Math.max(
          lastFetchedTimestampRef.current || 0,
          ...data.map((pool) => Number(pool.latestActivityTimestamp))
        );
        lastFetchedTimestampRef.current = latestTimestamp;
      }
    } catch (error) {
      console.error('Failed to fetch transactions:', error);
      // Do not update the loading state or show any error messages
    }
  }, [loading]);

  useEffect(() => {
    fetchTransactions();
    const intervalId = setInterval(fetchTransactions, 4000);
    return () => clearInterval(intervalId);
  }, [fetchTransactions]);

  const adjustedTransactions = useMemo(() => {
    return transactions.map((pool) => {
      const adjustedPool = { ...pool };
      const decimals = pool.decimals !== undefined ? pool.decimals : 0;

      adjustedPool.realSuiReserves =
        parseFloat(pool.realSuiReserves || '0') / 1e9;
      adjustedPool.realTokenReserves =
        parseFloat(pool.realTokenReserves || '0') / Math.pow(10, decimals);

      adjustedPool.volume24h = parseFloat(pool.volume24h || '0');

      // Use currentPrice from server, which is in SUI
      adjustedPool.currentPriceInSUI = parseFloat(pool.currentPrice || '0');

      adjustedPool.currentPriceInUSD = adjustedPool.currentPriceInSUI * suiPrice;

      adjustedPool.volume24hInUSD = adjustedPool.volume24h * suiPrice;

      adjustedPool.tokenATH = parseFloat(pool.tokenATH || '0') * suiPrice || 0;

      adjustedPool.priceChange24h = parseFloat(pool.priceChange24h || '0');

      adjustedPool.marketCapInSUI = parseFloat(pool.marketCapInSUI || '0');

      adjustedPool.marketCapInUSD = adjustedPool.marketCapInSUI * suiPrice;

      adjustedPool.transactions = (pool.transactions || [])
        .filter(
          (tx) =>
            tx.timestampMs &&
            !isNaN(Number(tx.timestampMs)) &&
            tx.price !== undefined &&
            !isNaN(Number(tx.price))
        )
        .map((tx) => ({
          ...tx,
          suiAmount: parseFloat(tx.suiAmount || '0') / 1e9,
          tokenAmount:
            parseFloat(tx.tokenAmount || '0') / Math.pow(10, decimals),
          priceInUSD: parseFloat(tx.price || '0') * suiPrice,
          timestampMs: Number(tx.timestampMs),
        }));

      // Ensure historicalPriceData is adjusted
      adjustedPool.historicalPriceData = (pool.historicalPriceData || [])
        .map((dataPoint) => ({
          ...dataPoint,
          timestampMs: Number(dataPoint.timestampMs),
          priceInUSD: parseFloat(dataPoint.price || '0') * suiPrice,
        }))
        .filter(
          (dataPoint) =>
            dataPoint.timestampMs &&
            !isNaN(dataPoint.timestampMs) &&
            dataPoint.priceInUSD !== undefined &&
            !isNaN(dataPoint.priceInUSD)
        );

      return adjustedPool;
    });
  }, [transactions, suiPrice]);

  const handleSearch = useCallback(
    (event) => setSearchTerm(event.target.value),
    []
  );

  const handleSortChange = useCallback((event) => {
    const value = event.target.value;
    if (value.startsWith('order_')) {
      setSortOrder(value.replace('order_', ''));
    } else if (value.startsWith('sortBy_')) {
      setSortParameter(value.replace('sortBy_', ''));
    }
  }, []);

  useEffect(() => {
    const sortLabels = {
      tokenAge: 'Token Age',
      latestActivity: 'Latest Activity',
      bondingCurve: 'Bonding Curve Progress',
      volume: '24h Volume',
      marketCap: 'Market Cap',
      priceChange24h: '24h Change',
    };

    const orderLabels = {
      asc: 'Asc.',
      desc: 'Desc.',
    };

    const sortLabel = sortLabels[sortParameter] || '';
    const orderLabel = orderLabels[sortOrder] || '';

    setSortDisplayText(`Sort By: ${sortLabel} (${orderLabel})`);
  }, [sortParameter, sortOrder]);

  const flashTimersRef = useRef({});

  useEffect(() => {
    if (initialLoadRef.current) {
      if (adjustedTransactions.length === 0) {
        return;
      }
      initialLoadRef.current = false;
      const digests = {};
      adjustedTransactions.forEach((pool) => {
        if (pool.transactions && pool.transactions[0]) {
          digests[pool.poolId] = pool.transactions[0].digest;
        }
      });
      prevLatestTransactionDigests.current = digests;
      return;
    }

    const newFlashPoolCards = {};
    adjustedTransactions.forEach((pool) => {
      const poolId = pool.poolId;
      const lastTransaction = pool.transactions && pool.transactions[0];
      const lastTransactionDigest = lastTransaction
        ? lastTransaction.digest
        : null;

      const prevLastTransactionDigest =
        prevLatestTransactionDigests.current[poolId];

      if (
        lastTransactionDigest &&
        prevLastTransactionDigest !== lastTransactionDigest
      ) {
        const flashClass =
          lastTransaction.isBuy === 'Buy' ? 'flash-buy' : 'flash-sell';
        newFlashPoolCards[poolId] = flashClass;

        // Clear existing timer if any
        if (flashTimersRef.current[poolId]) {
          clearTimeout(flashTimersRef.current[poolId]);
        }

        // Set the timer to remove the flash class after 1 second
        flashTimersRef.current[poolId] = setTimeout(() => {
          setFlashPoolCards((prev) => {
            const updated = { ...prev };
            delete updated[poolId];
            return updated;
          });
          delete flashTimersRef.current[poolId];
        }, 1000);
      }
    });

    // Update flashPoolCards state
    if (Object.keys(newFlashPoolCards).length > 0) {
      setFlashPoolCards((prev) => ({ ...prev, ...newFlashPoolCards }));
    }

    // Update prevLatestTransactionDigests after processing
    const newLatestTransactionDigests = {};
    adjustedTransactions.forEach((pool) => {
      const poolId = pool.poolId;
      const lastTransaction = pool.transactions && pool.transactions[0];
      const lastTransactionDigest = lastTransaction
        ? lastTransaction.digest
        : null;
      newLatestTransactionDigests[poolId] = lastTransactionDigest;
    });
    prevLatestTransactionDigests.current = newLatestTransactionDigests;

    // Clean up timers when component unmounts
    return () => {
      Object.values(flashTimersRef.current).forEach(clearTimeout);
      flashTimersRef.current = {};
    };
  }, [adjustedTransactions]);

  const filteredAndSortedTransactions = useMemo(() => {
    let pools = adjustedTransactions
      .filter((pool) => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
          pool.tokenName.toLowerCase().includes(searchTermLower) ||
          pool.tokenSymbol.toLowerCase().includes(searchTermLower) ||
          pool.tokenAddress.toLowerCase().includes(searchTermLower)
        );
      })
      .sort((a, b) => {
        let result = 0;

        if (sortParameter === 'tokenAge') {
          result = b.launchTimestamp - a.launchTimestamp;
        } else if (sortParameter === 'latestActivity') {
          result = b.latestActivityTimestamp - a.latestActivityTimestamp;
        } else if (sortParameter === 'bondingCurve') {
          result = b.bondingCurveProgress - a.bondingCurveProgress;
        } else if (sortParameter === 'volume') {
          result = b.volume24hInUSD - a.volume24hInUSD;
        } else if (sortParameter === 'marketCap') {
          result = b.marketCapInUSD - a.marketCapInUSD;
        } else if (sortParameter === 'priceChange24h') {
          result = b.priceChange24h - a.priceChange24h;
        }

        if (sortOrder === 'asc') {
          result = -result;
        }

        if (result === 0) {
          return a.poolId.localeCompare(b.poolId);
        }
        return result;
      });

    return pools;
  }, [adjustedTransactions, searchTerm, sortOrder, sortParameter]);

  const poolsWithRank = useMemo(() => {
    const sortedByBondingCurve = [...filteredAndSortedTransactions].sort(
      (a, b) => b.bondingCurveProgress - a.bondingCurveProgress
    );
    const poolRanks = {};
    sortedByBondingCurve.forEach((pool, index) => {
      poolRanks[pool.poolId] = index + 1;
    });
    return poolRanks;
  }, [filteredAndSortedTransactions]);

  const handlePinClick = useCallback(
    (poolId) => {
      if (pinnedPoolId === poolId) {
        const index = filteredAndSortedTransactions.findIndex(
          (pool) => pool.poolId === poolId
        );
        if (index !== -1) {
          const pageNumber = Math.floor(index / sectionsPerPage) + 1;
          setCurrentPage(pageNumber);
        }
        setPinnedPoolId(null);
      } else {
        setPinnedPoolId(poolId);
        setCurrentPage(1);
      }
    },
    [pinnedPoolId, filteredAndSortedTransactions, sectionsPerPage]
  );

  const indexOfLastSection = currentPage * sectionsPerPage;
  const indexOfFirstSection = indexOfLastSection - sectionsPerPage;

  const currentSections = useMemo(() => {
    let sections = filteredAndSortedTransactions.slice(
      indexOfFirstSection,
      indexOfLastSection
    );
    if (pinnedPoolId) {
      const pinnedPool = filteredAndSortedTransactions.find(
        (pool) => pool.poolId === pinnedPoolId
      );
      if (pinnedPool) {
        sections = sections.filter((pool) => pool.poolId !== pinnedPoolId);
        sections.unshift(pinnedPool);
        sections = sections.slice(0, sectionsPerPage);
      }
    }
    return sections;
  }, [
    filteredAndSortedTransactions,
    indexOfFirstSection,
    indexOfLastSection,
    pinnedPoolId,
    sectionsPerPage,
  ]);

  const totalPages = Math.ceil(
    filteredAndSortedTransactions.length / sectionsPerPage
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [hoveredPoolId, setHoveredPoolId] = useState(null);

  const handleMouseEnter = useCallback((poolId) => {
    setHoveredPoolId(poolId);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHoveredPoolId(null);
  }, []);

  const openModal = useCallback(
    async (pool) => {
      const adjustedPool = adjustedTransactions.find(
        (p) => p.poolId === pool.poolId
      );
      if (adjustedPool) {
        setModalPool(adjustedPool);
        setModalPage(1);
      } else {
        setModalPool(pool);
        setModalPage(1);
      }
    },
    [adjustedTransactions]
  );

  useEffect(() => {
    const fetchModalTransactions = async () => {
      if (!modalPool) return;

      try {
        const response = await fetch(
          `https://api.suifox.com/poolTransactions?poolId=${modalPool.poolId}&page=${modalPage}&limit=${transactionsPerPage}`
        );

        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();
        const decimals =
          modalPool.decimals !== undefined ? modalPool.decimals : 0;
        const adjustedTxs = data.transactions.map((tx) => ({
          ...tx,
          suiAmount: parseFloat(tx.suiAmount || '0') / 1e9,
          tokenAmount:
            parseFloat(tx.tokenAmount || '0') / Math.pow(10, decimals),
          priceInUSD: parseFloat(tx.price || '0') * suiPrice,
          timestampMs: Number(tx.timestampMs),
        }));
        setModalTransactions(adjustedTxs);
        setModalTotalPages(data.totalPages);
      } catch (error) {
        console.error('Failed to fetch modal transactions:', error);
        setModalTransactions([]);
      }
    };

    fetchModalTransactions();
  }, [modalPool, modalPage, suiPrice]);

  const PoolCard = React.memo(
    function PoolCard(props) {
      const {
        pool,
        flashClass,
        rank,
        hoveredPoolId,
        pinnedPoolId,
        handleMouseEnter,
        handleMouseLeave,
        handlePinClick,
        openModal,
      } = props;

      const {
        poolId,
        tokenName,
        tokenSymbol,
        tokenAddress,
        bondingCurveProgress,
        iconUrl,
        volume24hInUSD,
        currentPriceInUSD,
        priceChange24h,
        marketCapInUSD,
        launchTimestamp,
        latestActivityTimestamp,
      } = pool;

      return (
        <div
          key={poolId}
          className={`pool-card ${flashClass || ''} ${
            pinnedPoolId === poolId ? 'pinned' : ''
          }`}
          onClick={() => openModal(pool)}
          onMouseEnter={() => handleMouseEnter(poolId)}
          onMouseLeave={handleMouseLeave}
        >
          <div className="pool-card-row">
            <div
              className={`rank-display ${
                hoveredPoolId === poolId || pinnedPoolId === poolId
                  ? 'visible'
                  : ''
              }`}
            >
              #{rank}
            </div>

            <div className="token-image-name">
              {iconUrl && (
                <img
                  src={iconUrl}
                  alt={`${tokenName} icon`}
                  className="token-image"
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop if loading.svg fails
                    e.target.src = '/loading.svg'; // Fallback image
                  }}
                />
              )}
              <div className="token-info">
                <div className="token-name">{tokenName}</div>
                <div className="token-symbol">{tokenSymbol}</div>
              </div>
            </div>

            <div className="token-stats">
              <div className="token-stats-row">
                <div className="token-stat">
                  <div className="token-header">Price</div>
                  <div>
                    $
                    {currentPriceInUSD
                      ? formatPrice(currentPriceInUSD)
                      : 'N/A'}
                  </div>
                </div>
                <div className="token-stat">
                  <div className="token-header">24h Change</div>
                  <div
                    className={
                      priceChange24h >= 0
                        ? 'positive-change'
                        : 'negative-change'
                    }
                  >
                    {priceChange24h !== null
                      ? formatPercentage(priceChange24h)
                      : 'N/A'}
                  </div>
                </div>
                <div className="token-stat">
                  <div className="token-header">Market Cap</div>
                  <div>
                    $
                    {marketCapInUSD
                      ? formatLargeNumber(marketCapInUSD)
                      : 'N/A'}
                  </div>
                </div>
              </div>
              <div className="token-stats-row">
                <div className="token-stat">
                  <div className="token-header">24h Vol</div>
                  <div>
                    $
                    {volume24hInUSD
                      ? formatLargeNumber(volume24hInUSD)
                      : 'N/A'}
                  </div>
                </div>
                <div className="token-stat">
                  <div className="token-header">Activity</div>
                  <div>
                    <TimeAgo timestamp={Number(latestActivityTimestamp)} />
                  </div>
                </div>
                <div className="token-stat">
                  <div className="token-header">Launch</div>
                  <div>
                    <TimeAgo timestamp={Number(launchTimestamp)} />
                  </div>
                </div>
              </div>
            </div>

            <div className="button-container">
              <a
                href={`https://movepump.com/token/${tokenAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                className="section-button"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src="/movepump.svg"
                  alt="Buy on Movepump"
                  className="movepump-logo"
                />
              </a>
            </div>

            <div
              className={`pin-icon ${
                hoveredPoolId === poolId || pinnedPoolId === poolId
                  ? 'visible'
                  : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handlePinClick(poolId);
              }}
            >
              <img
                src="/pin.svg"
                alt="Pin Icon"
                className={`pin-image ${
                  pinnedPoolId === poolId ? 'pinned' : ''
                }`}
              />
            </div>
          </div>

          <div className="progress-bar-container">
            <div
              className="progress-dynamic"
              style={{ width: `${bondingCurveProgress}%` }}
            ></div>
            <div className="progress-text">{bondingCurveProgress}%</div>
          </div>
        </div>
      );
    },
    (prevProps, nextProps) => {
      const poolId = prevProps.pool.poolId;

      if (prevProps.flashClass !== nextProps.flashClass) {
        return false;
      }

      if (prevProps.rank !== nextProps.rank) {
        return false;
      }

      if (
        (prevProps.hoveredPoolId === poolId) !==
        (nextProps.hoveredPoolId === poolId)
      ) {
        return false;
      }

      if (
        (prevProps.pinnedPoolId === poolId) !==
        (nextProps.pinnedPoolId === poolId)
      ) {
        return false;
      }

      return true;
    }
  );

  const pageNumbers = [];
  const startPage = Math.max(1, currentPage - Math.floor(3 / 2));
  const endPage = Math.min(totalPages, startPage + 2);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="full-width-wrapper">
      <header className="sticky-header">
        <img src="/logo.svg" alt="Logo" className="header-logo" />
        <div className="header-buttons">
          <a
            href="https://twitter.com/SuifoxOfficial"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <img src="/twitter.svg" alt="Twitter" className="social-icon" />
          </a>
          <a
            href="https://t.me/SuifoxOfficial"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Telegram"
          >
            <img src="/telegram.svg" alt="Telegram" className="social-icon" />
          </a>
          <a
            href="https://movepump.com/token/0x20db32ba60a737b723331d1065ef481fc470788fa0729fa582ccfa2bdbda9cd2::suifox::SUIFOX"
            target="_blank"
            rel="noopener noreferrer"
            className="buy-suifox-button"
            style={{ textDecoration: 'none' }}
          >
            Buy Suifox
          </a>
        </div>
      </header>

      {showNotification && (
        <div className="notification-bar">
          <div className="notification-text">
            <span className="red-text">Important: </span>
            <span className="white-text">
              The Suifox community token address is
            </span>{' '}
            0x20db32ba60a737b723331d1065ef481fc470788fa0729fa582ccfa2bdbda9cd2::suifox::SUIFOX
          </div>
          <button onClick={closeNotification} className="close-notification">
            &times;
          </button>
        </div>
      )}

      <div className="App">
        <main
          className="App-main"
          style={{ paddingTop: showNotification ? '60px' : '60px' }}
        >
          <div className="search-and-sort">
            <input
              type="text"
              placeholder="Search by Name, Symbol, Address..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />

            <div className="additional-options">
              <div className="tooltip">
                <button
                  className="filters-button"
                  onClick={(e) => e.preventDefault()}
                >
                  Filters
                </button>
                <span className="tooltiptext">Coming Soon</span>
              </div>
              <img src="/logo.svg" alt="Logo" className="logo-between-buttons" />
              <div className="tooltip">
                <button
                  className="premium-button"
                  onClick={(e) => e.preventDefault()}
                >
                  Premium
                </button>
                <span className="tooltiptext">Coming Soon</span>
              </div>
            </div>

            <select
              value=""
              onChange={handleSortChange}
              className="sort-select"
            >
              <option value="" disabled>
                {sortDisplayText}
              </option>
              <option disabled>Order</option>
              <option value="order_desc">Descending</option>
              <option value="order_asc">Ascending</option>
              <option disabled>Sort By</option>
              <option value="sortBy_tokenAge">Token Age</option>
              <option value="sortBy_latestActivity">Latest Activity</option>
              <option value="sortBy_bondingCurve">Bonding Curve Progress</option>
              <option value="sortBy_volume">24h Volume</option>
              <option value="sortBy_marketCap">Market Cap</option>
              <option value="sortBy_priceChange24h">24h Change</option>
            </select>
          </div>

          {loading && transactions.length === 0 ? (
            <div className="loading-container">
              <img
                src="/loading.svg"
                alt="Loading..."
                className="loading-spinner"
              />
            </div>
          ) : (
            <section>
              <h1 className="beta-header">Beta</h1>
              <h1 className="analytics-header">Movepump Analytics</h1>
              {currentSections.map((pool) => (
                <PoolCard
                  key={pool.poolId}
                  pool={pool}
                  flashClass={flashPoolCards[pool.poolId]}
                  rank={poolsWithRank[pool.poolId]}
                  hoveredPoolId={hoveredPoolId}
                  pinnedPoolId={pinnedPoolId}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  handlePinClick={handlePinClick}
                  openModal={openModal}
                />
              ))}

              <div className="pagination-controls">
                <button
                  disabled={currentPage === 1}
                  onClick={() => paginate(1)}
                  className="pagination-button"
                >
                  &lt;&lt;
                </button>
                <button
                  disabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                  className="pagination-button"
                >
                  &lt;
                </button>

                {pageNumbers.map((num) => (
                  <button
                    key={num}
                    onClick={() => paginate(num)}
                    className={`pagination-button ${
                      num === currentPage ? 'active' : ''
                    }`}
                  >
                    {num}
                  </button>
                ))}

                <button
                  disabled={currentPage === totalPages}
                  onClick={() => paginate(currentPage + 1)}
                  className="pagination-button"
                >
                  &gt;
                </button>
                <button
                  disabled={currentPage === totalPages}
                  onClick={() => paginate(totalPages)}
                  className="pagination-button"
                >
                  &gt;&gt;
                </button>
              </div>

              <footer className="footer">
                <div className="footer-content">
                  <div className="footer-left">
                    <p>&copy; Suifox 2024</p>
                    <p>
                      Made by{' '}
                      <a
                        href="https://x.com/0xr4bb1tz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        0xR4bb1tz
                      </a>
                    </p>
                  </div>
                  <div className="footer-right">
                    <ul className="footer-links">
                      <li>
                        <a
                          href="https://twitter.com/SuifoxOfficial"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Twitter
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://t.me/SuifoxOfficial"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Telegram
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://suifox.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Analytics
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://movepump.com/token/0x20db32ba60a737b723331d1065ef481fc470788fa0729fa582ccfa2bdbda9cd2::suifox::SUIFOX`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Token
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://suifox.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Docs
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </footer>
            </section>
          )}
        </main>
      </div>

      {modalPool && (
        <Modal onClose={() => setModalPool(null)}>
          <div className="modal-token-info">
            <div className="modal-token-header">
              <div className="modal-rank-display">
                #{poolsWithRank[modalPool.poolId]}
              </div>

              <div className="modal-token-image-name">
                {modalPool.iconUrl && (
                  <img
                    src={modalPool.iconUrl}
                    alt={`${modalPool.tokenName} icon`}
                    className="token-image-modal"
                    loading="lazy"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/loading.svg';
                    }}
                  />
                )}
                <div className="modal-token-info-text">
                  <div className="token-name">{modalPool.tokenName}</div>
                  <div className="token-symbol">{modalPool.tokenSymbol}</div>
                </div>
              </div>
            </div>

            <div className="modal-token-stats">
              <div className="token-stat">
                <div className="token-header">SUI Reserves</div>
                <div>{formatAmount(modalPool.realSuiReserves)}</div>
              </div>
              <div className="token-stat">
                <div className="token-header">24h Volume</div>
                <div>
                  $
                  {modalPool.volume24hInUSD
                    ? formatLargeNumber(modalPool.volume24hInUSD)
                    : 'N/A'}
                </div>
              </div>
              <div className="token-stat">
                <div className="token-header">Price</div>
                <div>
                  $
                  {modalPool.currentPriceInUSD
                    ? formatPrice(modalPool.currentPriceInUSD)
                    : 'N/A'}
                </div>
              </div>
              <div className="token-stat">
                <div className="token-header">24h Change</div>
                <div
                  className={
                    modalPool.priceChange24h >= 0
                      ? 'positive-change'
                      : 'negative-change'
                  }
                >
                  {modalPool.priceChange24h !== null
                    ? formatPercentage(modalPool.priceChange24h)
                    : 'N/A'}
                </div>
              </div>
              <div className="token-stat">
                <div className="token-header">Market Cap</div>
                <div>
                  $
                  {modalPool.marketCapInUSD
                    ? formatLargeNumber(modalPool.marketCapInUSD)
                    : 'N/A'}
                </div>
              </div>
              <div className="token-stat">
                <div className="token-header">Launch</div>
                <div>
                  <TimeAgo timestamp={Number(modalPool.launchTimestamp)} />
                </div>
              </div>
              <div className="token-stat">
                <div className="token-header">Activity</div>
                <div>
                  <TimeAgo
                    timestamp={Number(modalPool.latestActivityTimestamp)}
                  />
                </div>
              </div>
              <div className="token-stat">
                <div className="token-header">Address</div>
                <button
                  className="copy-button"
                  onClick={() => copyToClipboard(modalPool.tokenAddress)}
                >
                  {shortenAddress(modalPool.tokenAddress, true)}
                </button>
              </div>
            </div>

            <div className="progress-bar-container">
              <div
                className="progress-dynamic"
                style={{ width: `${modalPool.bondingCurveProgress}%` }}
              ></div>
              <div className="progress-text">
                {modalPool.bondingCurveProgress}%
              </div>
            </div>

            <div className="chart-container-modal">
              {modalPool.historicalPriceData &&
              modalPool.historicalPriceData.length > 0 ? (
                (() => {
                  const maxYValue =
                    modalPool.historicalPriceData.length > 0
                      ? Math.max(
                          ...modalPool.historicalPriceData.map(
                            (dp) => dp.priceInUSD || 0
                          )
                        )
                      : 0;
                  return (
                    <Line
                      data={{
                        datasets: [
                          {
                            label: 'Price',
                            data: modalPool.historicalPriceData.map(
                              (dataPoint) => ({
                                x: dataPoint.timestampMs,
                                y: parseFloat(dataPoint.priceInUSD),
                              })
                            ),
                            fill: false,
                            borderColor: '#4fdcdc',
                            backgroundColor: 'rgba(79, 220, 220, 0.2)',
                            tension: 0.1,
                            pointRadius: 0,
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        animation: false,
                        scales: {
                          x: {
                            type: 'time',
                            time: {
                              unit: 'hour',
                              tooltipFormat: 'MMM dd, yyyy HH:mm',
                            },
                            grid: {
                              display: false,
                            },
                          },
                          y: {
                            min: 0,
                            max: maxYValue > 0 ? maxYValue * 1.1 : undefined,
                            ticks: {
                              callback: function (value) {
                                return formatPrice(value);
                              },
                            },
                            grid: {
                              color: '#3a3b5a',
                            },
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                let label = context.dataset.label || '';
                                if (label) {
                                  label += ': ';
                                }
                                label += '$' + formatPrice(context.parsed.y);
                                return label;
                              },
                            },
                            mode: 'index',
                            intersect: false,
                          },
                        },
                      }}
                    />
                  );
                })()
              ) : (
                <div className="no-data">No historical data available.</div>
              )}
            </div>

            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>TX ID</th>
                    <th>Type</th>
                    <th>SUI</th>
                    <th>{modalPool.tokenSymbol}</th>
                    <th>Maker</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {modalTransactions.map((tx, index) => {
                    return (
                      <tr
                        key={index}
                        className={tx.isBuy === 'Buy' ? 'buy' : 'sell'}
                      >
                        <td>
                          <button
                            className="copy-button"
                            onClick={() => copyToClipboard(tx.digest)}
                          >
                            {shortenAddress(tx.digest)}
                          </button>
                        </td>
                        <td
                          className={
                            tx.isBuy === 'Buy' ? 'buy-text' : 'sell-text'
                          }
                        >
                          {tx.isBuy}
                        </td>
                        <td>{formatAmount(tx.suiAmount)}</td>
                        <td>{formatAmount(tx.tokenAmount)}</td>
                        <td>
                          <button
                            className="copy-button"
                            onClick={() => copyToClipboard(tx.makerAddress)}
                          >
                            {shortenAddress(tx.makerAddress || 'N/A')}
                          </button>
                        </td>
                        <td>
                          <TimeAgo timestamp={Number(tx.timestampMs)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="pagination-controls">
                <button
                  disabled={modalPage === 1}
                  onClick={() => setModalPage(1)}
                  className="pagination-button"
                >
                  &lt;&lt;
                </button>
                <button
                  disabled={modalPage === 1}
                  onClick={() => setModalPage(modalPage - 1)}
                  className="pagination-button"
                >
                  &lt;
                </button>

                <span className="modal-page-info">
                  Page {modalPage} of {modalTotalPages}
                </span>

                <button
                  disabled={modalPage === modalTotalPages}
                  onClick={() => setModalPage(modalPage + 1)}
                  className="pagination-button"
                >
                  &gt;
                </button>
                <button
                  disabled={modalPage === modalTotalPages}
                  onClick={() => setModalPage(modalTotalPages)}
                  className="pagination-button"
                >
                  &gt;&gt;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default App;
